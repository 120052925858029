import { useEffect, useState, useCallback, useRef } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container, Content, Panel, Stack, Heading, Loader, Checkbox, Table, List, Text, HStack, Toggle, Input, Row, Col, Grid, Message } from "rsuite";
import API from "../../utils/API";
import { format, parseISO, differenceInHours, formatISO, set } from "date-fns";

// import Document from "../standards/Document";

function WorkorderStartLabCuring(props) {
  let navigate = useNavigate();
  let { workorderUUID } = useParams();
  const [workorder, set_workorder] = useState();
  const [workorder_is_loading, set_workorder_is_loading] = useState(true);

  const sigCanvas = useRef({});
  const { Column, HeaderCell, Cell } = Table;

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();

  const { fields: cylinder_specimens } = useFieldArray({ control, name: "cylinder_specimens" });

  useEffect(() => {
    API.get(`/workorders/field/${workorderUUID}`)
      .then((r) => {
        set_workorder(r.data);
        set_workorder_is_loading(false);
        const cylinder_specimens = r.data?.concrete_samples?.flatMap((cs) =>
          cs?.cylinder_specimens.map((specimen) => {
            const sample_date = cs?.sample_date && format(parseISO(cs?.sample_date), "y-MM-dd");
            const sample_time = cs?.sample_time && format(parseISO(cs?.sample_time.split("Z")[0]), "HH:mm");
            const sampled = parseISO(`${sample_date}T${sample_time}:00`);
            const now = new Date();
            const hoursDifference = differenceInHours(now, sampled);
            // console.log("SAMPLED:", sampled, "NOW:", now, "Difference:", hoursDifference);

            // 2024-08-30 12:56:00.000 +1000 PICKED UP TIMESTAMP
            // START CURING TIMESTAMP
            // PICKEDUP / START = INTIAL CURING
            // IF <18 HOURS WE NEED TO RECORD REASON
            // IF >36 HOURS WE NEED TO RECORD REASON + REASON FOR DELAY + MIN/MAX TEMPS

            return {
              specimen_id: specimen.id,
              sampled: sampled || null,
              concrete_sample_id: cs.id,
              initial_curing: hoursDifference || null,
            };
          })
        );
        reset({
          workorder_uuid: workorderUUID,
          curing_date: new Date(),
          custom_lab_curing_start: false,
          custom_lab_curing_start_date: format(new Date(), "Y-MM-dd"),
          custom_lab_curing_start_time: format(new Date(), "HH:mm"),
          cylinder_specimens: cylinder_specimens,
          non_std_initial_reason: "",
          non_std_initial_curing: cylinder_specimens?.some((specimen) => specimen.initial_curing < 18 || specimen.initial_curing > 36),
          non_std_initial_curing_over_36: cylinder_specimens?.some((specimen) => specimen.initial_curing > 36),
        });
      })
      .catch((e) => console.log(e));
  }, [workorderUUID]);

  const calculateInitialCuring = (specimen, PickedUpDateTime) => {
    const hoursDifference = differenceInHours(PickedUpDateTime, specimen.sampled);
    return hoursDifference; // Replace with actual calculation logic
  };

  const recalculateInitialCuring = () => {
    let curing_time = new Date();
    if (watch("custom_lab_curing_start")) {
      curing_time = parseISO(`${getValues("custom_lab_curing_start_date")}T${getValues("custom_lab_curing_start_time")}:00`);
    }

    const updatedCylinders = cylinder_specimens?.map((specimen) => {
      const initialCuring = calculateInitialCuring(specimen, curing_time);
      return {
        ...specimen,
        initial_curing: initialCuring,
      };
    });

    const hasNonStandardCuring = updatedCylinders?.some((specimen) => specimen.initial_curing < 18 || specimen.initial_curing > 36);
    const hasNonStandardCuringOver36 = updatedCylinders?.some((specimen) => specimen.initial_curing > 36);

    setValue("non_std_initial_curing", hasNonStandardCuring);
    setValue("non_std_initial_curing_over_36", hasNonStandardCuringOver36);
    setValue("cylinder_specimens", updatedCylinders);
  };

  const hChoosePickTime = (e) => {
    setValue("custom_lab_curing_start", !watch("custom_lab_curing_start"));
    setValue("custom_lab_curing_start_date", format(new Date(), "Y-MM-dd"));
    setValue("custom_lab_curing_start_time", format(new Date(), "HH:mm"));
    recalculateInitialCuring();
  };

  const hPickDate = (e) => {
    if (e) {
      setValue("custom_lab_curing_start_date", e);
    }
    recalculateInitialCuring();
    console.log(e);
    // Recalculate the initial curing
  };
  const hPickTime = (e) => {
    if (e) {
      setValue("custom_lab_curing_start_time", e);
    }
    recalculateInitialCuring();
    console.log(e);
    // Recalculate the initial curing
  };

  const onSubmit = (data) => {
    if (data.custom_lab_curing_start) {
      data.curing_timestamp = formatISO(parseISO(`${data.custom_lab_curing_start_date}T${data.custom_lab_curing_start_time}:00`));
    } else {
      data.curing_timestamp = formatISO(data.curing_date);
    }

    API.post(`/workorders/field/${workorderUUID}/start_lab_curing`, data)
      .then((r) => navigate(`/workorders/${workorderUUID}`))
      .catch((e) => console.log(e.response.data.errors));
  };

  if (workorder_is_loading) {
    return <Loader center content="Loading..." />;
  } else {
    return (
      <Container>
        <Content>
          <Panel>
            <Stack alignItems="center" justifyContent="space-between">
              <Stack.Item>
                <Heading level={6}>Start Lab Curing</Heading>
              </Stack.Item>
            </Stack>
          </Panel>

          <Panel>
            <Form onSubmit={handleSubmit(onSubmit)} hidden={cylinder_specimens?.length == 0 ? true : false}>
              <Grid>
                <Row>
                  <Col xs={24}>
                    <label>Cylinders: </label>
                    {cylinder_specimens?.length}
                  </Col>
                </Row>

                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <label>Choose Curing Start: </label>
                  </Col>
                  <Col xs={12}>
                    <Toggle checked={watch("custom_lab_curing_start")} onChange={(e) => hChoosePickTime(e)} />
                  </Col>
                </Row>

                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <label>Non-Std Initial Curing: </label>
                  </Col>
                  <Col xs={12}>
                    <Controller
                      name="non_std_initial_curing"
                      control={control}
                      render={({ field }) => <Toggle disabled checked={field.value} id={field.name} value={field.value} onChange={(v) => field.onChange(v)} />}
                    />
                  </Col>
                </Row>

                <Row hidden={!watch("custom_lab_curing_start")} style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <Text>Date</Text>
                  </Col>
                  <Col xs={12}>
                    <Controller
                      name="custom_lab_curing_start_date"
                      control={control}
                      render={({ field }) => (
                        <Input
                          size="md"
                          type="date"
                          min="2000-01-01"
                          max="2050-01-01"
                          // defaultValue={format(new Date(), "y-MM-dd")}
                          id={field.name}
                          value={field.value}
                          onChange={(v) => hPickDate(v)}
                          placeholder="Batch Date"
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row hidden={!watch("custom_lab_curing_start")} style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <Text>Time</Text>
                  </Col>
                  <Col xs={12}>
                    <Controller
                      name="custom_lab_curing_start_time"
                      control={control}
                      render={({ field }) => <Input size="md" type="time" id={field.name} value={field.value} onChange={(v) => hPickTime(v)} />}
                    />
                  </Col>
                </Row>

                <Row hidden={!watch("non_std_initial_curing")} style={{ marginTop: "1rem" }}>
                  <Col xs={24}>
                    <Controller
                      name="non_std_initial_reason"
                      control={control}
                      rules={{ required: watch("non_std_initial_curing") }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Input as="textarea" rows={3} id={field.name} value={field.value} onChange={(value) => field.onChange(value)} placeholder="Reason" />
                          {error && <Form.HelpText style={{ color: "red" }}>A reason is required</Form.HelpText>}
                        </>
                      )}
                    />

                    <Message type="warning" bordered header="Non-Standard Curing" style={{ marginTop: "1rem" }}>
                      <ol>
                        <li hidden={!watch("non_std_initial_curing_over_36")}>
                          <b>Record the actual curing temperatures</b>
                          <br />
                          Both minimum and maximum temperatures should be documented.
                        </li>
                        <li>
                          <b>Provide reasons for non-standard curing</b>
                          <br />
                          It's necessary to justify why the standard curing conditions could not be maintained.
                        </li>
                      </ol>
                    </Message>
                  </Col>
                </Row>
              </Grid>
              <Button style={{ marginTop: "1rem" }} block appearance="primary" type="submit" disabled={isSubmitting}>
                {!watch("custom_lab_curing_start")
                  ? "Start Lab Curing Now"
                  : `Start at ${watch("custom_lab_curing_start_time")} on ${format(parseISO(watch("custom_lab_curing_start_date")), "dd-MM-Y")}`}
              </Button>
            </Form>
          </Panel>

          <Panel hidden={cylinder_specimens?.length == 0 ? false : true}>
            <Message type="info">
              <p>Nothing to pickup.</p>
            </Message>
          </Panel>

          {/* <Document /> */}
        </Content>
      </Container>
    );
  }
}

export default WorkorderStartLabCuring;
