import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountConsumer } from "../../context/AccountProvider";

export default function Logout() {
  let navigate = useNavigate();
  const updateAccount = useContext(AccountConsumer);
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    updateAccount.updateAccount({
      id: undefined,
    });
    navigate("/login");
  });
}
