import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AccountConsumer } from "../../context/AccountProvider";
import API from "../../utils/API";
import JWTDecode from "jwt-decode";


function FormLogin() {
  let navigate = useNavigate();
  const updateAccount = useContext(AccountConsumer);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (data) => {
    API.post(`/login`, data)
      .then((r) => {
        localStorage.setItem("access_token", r.data.token);
        localStorage.setItem("refresh_token", r.data.refresh_token);
        const { user_id, username, first_name } = JWTDecode(localStorage.getItem("access_token"));

        updateAccount.updateAccount({
          user_id: user_id,
          username: username,
          first_name: first_name,
        });
        navigate("/");
      })
      .catch((e) => console.log(e.response.data.errors));
  };

  return (
    <div className="login-container">
    <div className="login-header">
      <img src="/logo.png" alt="image" />
    </div>
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <label htmlFor="username" className="bold">
        Username:
      </label>
      <input type="text" {...register("username")} />
      {errors.username && <span>This field is required</span>}

      <label htmlFor="password" className="bold">
        Password:
      </label>
      <input type="password" {...register("password")} />
      {errors.password && <span>This field is required</span>}

      <input type="submit" disabled={isSubmitting} value={"Login"} />
    </form>
    </div>
  );
}

export default FormLogin;
