import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import SignatureCanvas from "react-signature-canvas";
import AsyncSelect from "react-select/async";
import API from "../../utils/API";
import { format, parseISO, subDays } from "date-fns";
import { Tag, TagGroup } from "rsuite";

function WorkorderForm(props) {
  let navigate = useNavigate();
  let { workorderUUID } = useParams();
  const sigCanvas = useRef({});

  const [searchParams] = useSearchParams();

  const mode = searchParams.get("m");
  const is_add_mode = !workorderUUID;
  const [clients, set_clients] = useState();
  // const [projects, set_projects] = useState();
  const [is_loaded, set_loaded] = useState(false);
  const [current_signature, set_current_signature] = useState(null);
  const [edit_signature, set_edit_signature] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const project = watch("project");

  const filterProjects = (inputValue) =>
    API.get(`/projects/search/${inputValue}`).then(
      (r) =>
        (r.data.data = r.data.data.map(({ id: value, name: label, locations, status }) => ({
          value,
          label,
          locations,
          status,
        })))
    );

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterProjects(inputValue));
    });

  function formatOptionLabel({ label, locations, status }, { context, inputValue }) {
    return context === "menu" ? (
      <div style={{ display: "flex", textTransform: "uppercase", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <div style={{ fontWeight: "bold", textDecoration: "underline" }}>{label}</div>
          <div style={{ color: "black", textTransform: "uppercase", fontFamily: "monospace" }}>
            {locations && locations[0].address_line_1} {locations && locations[0].address_line_2}
          </div>
        </div>
        <div>
          {status === 1 && (
            <TagGroup>
              <Tag color="green">Active</Tag>
            </TagGroup>
          )}
          {status === 2 && (
            <TagGroup>
              <Tag color="orange">Inactive</Tag>
            </TagGroup>
          )}
        </div>
      </div>
    ) : (
      <div>
        <div>{label}</div>
      </div>
    );
  }

  useEffect(() => {
    API.get(`/clients`, { params: { limit: 200 } })
      .then((r) => {
        set_clients((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
      })
      .catch((e) => console.log(e));

    if (!is_add_mode) {
      API.get(`/workorders/field/${workorderUUID}`)
        .then((r) => {
          reset({
            id: r.data.id,
            work_date: r.data.work_date && format(parseISO(r.data.work_date), "Y-MM-dd"),
            work_start_time: r.data.work_start_time && format(parseISO(r.data.work_start_time.split("Z")[0]), "HH:mm"),
            work_finish: r.data.work_finish && format(parseISO(r.data.work_finish), "Y-MM-dd"),
            work_finish_time: r.data.work_finish_time && format(parseISO(r.data.work_finish_time.split("Z")[0]), "HH:mm"),
            billing_customer: r.data.billing_customer && r.data.billing_customer.id,
            billing_customer_ref: r.data.billing_customer_ref && r.data.billing_customer_ref,
            project: r.data.project && { value: r.data.project.id, label: r.data.project.name },
            field_address_line_1: r.data.field_address_line_1 && r.data.field_address_line_1,
            field_address_line_2: r.data.field_address_line_2 && r.data.field_address_line_2,
            field_customer: r.data.field_customer && r.data.field_customer,
            field_instructions: r.data.field_instructions && r.data.field_instructions,
            // field_assigned: r.data.field_assigned && r.data.field_assigned,
            // field_planned: r.data.field_planned && r.data.field_planned,
            field_complete: mode == "complete" ? true : r.data.field_complete,
            field_signature: (r.data.field_signature && r.data.field_signature) || undefined,
            field_signature_name: r.data.field_signature_name && r.data.field_signature_name,
          });
          set_loaded(true);
        })
        .catch((e) => console.log(e));
    } else {
      reset({
        work_date: format(new Date(), "Y-MM-dd"),
        work_finish: format(new Date(), "Y-MM-dd"),
      });
      set_loaded(true);
    }
  }, [workorderUUID, reset, is_add_mode, set_loaded]);

  const onSubmit = (data) => {
    if (current_signature && current_signature != data.field_signature) {
      data.field_signature = current_signature;
    }
    if (data.project && data.project.value) {
      data.project = data.project.value;
    }
    if (data.work_date === "") {
      data.work_date = null;
    }
    if (data.work_start_time === "") {
      data.work_start_time = null;
    }
    if (data.work_finish === "") {
      data.work_finish = null;
    }
    if (data.work_finish_time === "") {
      data.work_finish_time = null;
    }
    if (!is_add_mode) {
      API.patch(`/workorders/field/${workorderUUID}`, data)
        .then((r) => (mode = "complete" ? navigate(`/workorders`) : navigate(`/workorders/${workorderUUID}`)))
        .catch((e) => console.log(e.response.data.errors));
    } else {
      API.post("/workorders/field", data)
        .then((r) => navigate(`/workorders`))
        .catch((e) => console.log(e.response.data.errors));
    }
    console.log(data);
  };

  if (!is_loaded) {
    return "Loading...";
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="standard-field-form">
        <div className="form-column">
          <input type="hidden" {...register("id", { valueAsNumber: true })} />
          <input type="hidden" {...register("field_instructions")} />
          <input type="hidden" {...register("billing_customer_ref")} />

          <div className={mode == "complete" ? "div-hide" : ""}>
            <label htmlFor="project" className="bold">
              Project
            </label>

            <Controller
              name="project"
              control={control}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  styles={customStyles}
                  isClearable
                  cacheOptions
                  loadOptions={promiseOptions}
                  formatOptionLabel={formatOptionLabel}
                  // formatOptionLabel={formatOptionLabel}
                />
              )}
            />
            <div className="panel-info">
              <p>
                Start typing to find the project if you wanted azzurri moorebank it will show update search for company name and suburb is usually easiest, "azz moor" will work and
                similar, if you cant find it use the below.
              </p>
            </div>
          </div>

          <div className={project ? "div-hide" : ""}>
            <label htmlFor="billing_customer" className="bold">
              Customer
            </label>
            <Controller
              name="billing_customer"
              rules={{ required: !project }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <ReactSelect
                  isDisabled={project}
                  styles={customStyles}
                  options={clients}
                  value={clients && clients.find((c) => c.value === value)}
                  onChange={(val) => onChange(val ? val.value : null)}
                  isClearable="true"
                />
              )}
            />
            {errors.billing_customer && errors.billing_customer.type === "required" && (
              <div className="panel-info-red">
                <p>If there is no project select you must select a customer</p>
              </div>
            )}

            <br />
            <div className="form-row">
              <label htmlFor="field_address_line_1" className="bold">
                Street Address
              </label>
              <input {...register("field_address_line_1", { required: !project })} disabled={project} />
            </div>
            {errors.field_address_line_1 && (
              <div className="panel-info-red">
                <p>You must select a street address</p>
              </div>
            )}
            <div className="form-row">
              <label htmlFor="field_address_line_2" className="bold">
                Suburb
              </label>
              <input {...register("field_address_line_2", { required: !project })} disabled={project} />
            </div>
            {errors.field_address_line_2 && (
              <div className="panel-info-red">
                <p>You must select a suburb</p>
              </div>
            )}
            <div className="form-row">
              <label htmlFor="field_customer" className="bold">
                Customer
              </label>
              <input {...register("field_customer", { required: !project })} disabled={project} />
            </div>
            {errors.field_customer && (
              <div className="panel-info-red">
                <p>You must select an onsite-customer (whats on the docket)</p>
              </div>
            )}
          </div>

          <div className={is_add_mode ? "div-hide" : ""}>
            <div className="form-row">
              <label htmlFor="work_date" className="bold">
                Work Date
              </label>
              <input {...register("work_date")} type="date" min="2000-01-01" max="2050-01-01" />
              {errors.work_date && <span>This field is required</span>}
            </div>

            <div className="form-row">
              <label htmlFor="work_finish" className="bold">
                Work Finished
              </label>
              <input {...register("work_finish")} type="date" min="2000-01-01" max="2050-01-01" />
              {errors.work_finish && <span>This field is required</span>}
            </div>
            <div className="form-row">
              <label htmlFor="work_start_time" className="bold">
                Time Onsite
              </label>
              <input {...register("work_start_time")} type="time" />
              {errors.work_date && <span>This field is required</span>}
            </div>
            <div className="form-row">
              <label htmlFor="work_finish_time" className="bold">
                Time Offsite
              </label>
              <input {...register("work_finish_time")} type="time" />
              {errors.work_date && <span>This field is required</span>}
            </div>

            <div className={is_add_mode ? "div-hide" : ""}>
              <div className="form-row">
                <label htmlFor="field_complete" className="bold">
                  Work Complete
                </label>
                <input type="checkbox" {...register("field_complete")} />
                {errors.field_complete && <span>This field is required</span>}
              </div>
            </div>

            <div className={is_add_mode ? "div-hide" : ""}>
              <div className="sign-area">
                <div className="sign-header">Site Signature</div>

                {watch("field_signature") && (
                  <>
                    <img src={watch("field_signature")} />
                    <span style={{ float: "right" }}>{watch("field_signature_name")}</span>
                  </>
                )}
                {watch("field_signature") ? (
                  <span className="btn-warning" onClick={() => setValue("field_signature", "")}>
                    Reset Signature
                  </span>
                ) : (
                  <>
                    <Controller
                      control={control}
                      name="field_signature"
                      render={({ field: { value, onChange } }) => (
                        <SignatureCanvas ref={sigCanvas} onEnd={() => set_current_signature(sigCanvas.current.toDataURL())} canvasProps={{ className: "sign-body" }} />
                      )}
                    />
                    <div className="form-row" style={{ marginTop: "0.5rem" }}>
                      <label htmlFor="field_signature_name" className="bold">
                        Signed By
                      </label>
                      <input {...register("field_signature_name")} type="text" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <input
            className="btn-primary"
            type="submit"
            disabled={isSubmitting}
            value={is_add_mode ? "Add Workorder" : mode == "complete" ? "Complete Workorder" : "Update Workorder"}
          />
        </div>
      </form>
    );
  }
}

// const customStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     // border: "1px dotted pink",
//     // color: state.isSelected ? "red" : "blue",
//     // padding: 10,
//   }),
//   control: () => ({
//     // none of react-select's styles are passed to <Control />
//     // width: 100,
//   }),
//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = "opacity 300ms";

//     return { ...provided, opacity, transition };
//   },
// };

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: "rgb(1, 1, 1)",
    // borderBottom: "2px dotted green",
    // color: state.isSelected ? "yellow" : "black",
    // backgroundColor: state.isSelected ? "green" : "white",
  }),
  control: (provided) => ({
    ...provided,
    padding: "0.07rem",
    borderRadius: 0,
    colour: "rgb(1, 1, 1)",
    // textTransform: "capitalize",
    borderColor: "rgb(118, 118, 118);",
    minHeight: "0px",
    fontSize: "0.8rem",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0",
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "0",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export default WorkorderForm;
