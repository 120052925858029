import React, { useState } from "react";
import JWTDecode from "jwt-decode";

const AccountContext = React.createContext();
export const AccountConsumer = AccountContext;

export default function AccountProvider(props) {
  var SavedUserDetails = localStorage.getItem("access_token");

  if (SavedUserDetails) {
    SavedUserDetails = JWTDecode(SavedUserDetails);
  }

  const [userDetails, setUserDetails] = useState({
    user_id: SavedUserDetails ? SavedUserDetails.user_id : undefined,
    username: SavedUserDetails ? SavedUserDetails.username : undefined,
    first_name: SavedUserDetails ? SavedUserDetails.first_name : undefined,
    updateAccount: (updatedAccount) => updateAccount(updatedAccount),
  });

  const updateAccount = (updatedAccount) => {
    setUserDetails((prevState) => ({
      ...prevState,
      ...updatedAccount,
    }));
  };
  return <AccountContext.Provider value={userDetails}>{props.children}</AccountContext.Provider>;
}
