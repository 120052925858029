import { useEffect, useState, useCallback, useRef } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container, Content, Panel, Stack, Heading, Loader, Checkbox, Table, List, Text, HStack, Toggle, Input, Row, Col, Grid, Message } from "rsuite";
import API from "../../utils/API";
import { format, parseISO, differenceInHours, formatISO } from "date-fns";

// import Document from "../standards/Document";

function WorkorderPickup(props) {
  let navigate = useNavigate();
  let { workorderUUID } = useParams();
  const [workorder, set_workorder] = useState();
  const [workorder_is_loading, set_workorder_is_loading] = useState(true);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();

  const { fields: cylinder_specimens } = useFieldArray({ control, name: "cylinder_specimens" });

  useEffect(() => {
    API.get(`/workorders/field/${workorderUUID}`)
      .then((r) => {
        set_workorder(r.data);
        set_workorder_is_loading(false);
        const cylinder_specimens = r.data?.concrete_samples?.flatMap((cs) =>
          cs?.cylinder_specimens.map((specimen) => {
            return {
              specimen_id: specimen.id,
              concrete_sample_id: cs.id,
            };
          })
        );
        reset({
          workorder_uuid: workorderUUID,
          pickup_date: new Date(),
          custom_pickup: false,
          custom_pickup_date: format(new Date(), "Y-MM-dd"),
          custom_pickup_time: format(new Date(), "HH:mm"),
          cylinder_specimens: cylinder_specimens,
        });
      })
      .catch((e) => console.log(e));
  }, [workorderUUID]);

  const hChoosePickTime = (e) => {
    setValue("custom_pickup", !watch("custom_pickup"));
    setValue("custom_pickup_date", format(new Date(), "Y-MM-dd"));
    setValue("custom_pickup_time", format(new Date(), "HH:mm"));
  };

  const hPickDate = (e) => {
    if (e) {
      setValue("custom_pickup_date", e);
    }
  };
  const hPickTime = (e) => {
    if (e) {
      setValue("custom_pickup_time", e);
    }
  };

  const onSubmit = (data) => {
    if (data.custom_pickup) {
      data.pickup_timestamp = formatISO(parseISO(`${data.custom_pickup_date}T${data.custom_pickup_time}:00`));
    } else {
      data.pickup_timestamp = formatISO(data.pickup_date);
    }

    API.post(`/workorders/field/${workorderUUID}/pickup`, data)
      .then((r) => navigate(`/workorders/${workorderUUID}`))
      .catch((e) => console.log(e.response.data.errors));
  };

  if (workorder_is_loading) {
    return <Loader center content="Loading..." />;
  } else {
    return (
      <Container>
        <Content>
          <Panel>
            <Stack alignItems="center" justifyContent="space-between">
              <Stack.Item>
                <Heading level={6}>Pickup Samples</Heading>
              </Stack.Item>
            </Stack>
          </Panel>

          <Panel>
            <Form onSubmit={handleSubmit(onSubmit)} hidden={cylinder_specimens?.length == 0 ? true : false}>
              <Grid>
                <Row>
                  <Col xs={24}>
                    <label>Cylinders: </label>
                    {cylinder_specimens?.length}
                  </Col>
                </Row>

                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <label>Choose Pickup Time: </label>
                  </Col>
                  <Col xs={12}>
                    <Toggle checked={watch("custom_pickup")} onChange={(e) => hChoosePickTime(e)} />
                  </Col>
                </Row>

                <Row hidden={!watch("custom_pickup")} style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <Text>Date</Text>
                  </Col>
                  <Col xs={12}>
                    <Controller
                      name="custom_pickup_date"
                      control={control}
                      render={({ field }) => (
                        <Input
                          size="md"
                          type="date"
                          min="2000-01-01"
                          max="2050-01-01"
                          // defaultValue={format(new Date(), "y-MM-dd")}
                          id={field.name}
                          value={field.value}
                          onChange={(v) => hPickDate(v)}
                          placeholder="Batch Date"
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row hidden={!watch("custom_pickup")} style={{ marginTop: "1rem" }}>
                  <Col xs={12}>
                    <Text>Time</Text>
                  </Col>
                  <Col xs={12}>
                    <Controller
                      name="custom_pickup_time"
                      control={control}
                      render={({ field }) => <Input size="md" type="time" id={field.name} value={field.value} onChange={(v) => hPickTime(v)} />}
                    />
                  </Col>
                </Row>
              </Grid>
              <Button style={{ marginTop: "1rem" }} block appearance="primary" type="submit" disabled={isSubmitting}>
                {!watch("custom_pickup") ? "Pickup Now" : `Picked up at ${watch("custom_pickup_time")} on ${format(parseISO(watch("custom_pickup_date")), "dd-MM-Y")}`}
              </Button>
            </Form>
          </Panel>

          <Panel hidden={cylinder_specimens?.length == 0 ? false : true}>
            <Message type="info">
              <p>Nothing to pickup.</p>
            </Message>
          </Panel>

          {/* <Document /> */}
        </Content>
      </Container>
    );
  }
}

export default WorkorderPickup;
