import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AccountConsumer } from "../context/AccountProvider";

export const ProtectedRoute = ({ children }) => {
  let acc = useContext(AccountConsumer);
  if (!acc.user_id) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
