import axios from "axios";
import JWTDecode from "jwt-decode";

const API = axios.create({
  baseURL: window.location.hostname.includes("wsctapps") ? "https://api.wsctapps.com" : "http://localhost:8123",
  timeout: 10000,
});

API.interceptors.request.use(
  (reqConfig) => {
    reqConfig.headers.authorization = localStorage.getItem("access_token");
    if (reqConfig.url.includes("/refresh-token")) reqConfig.headers["X-REFRESH-TOKEN"] = localStorage.getItem("refresh_token");
    return reqConfig;
  },
  (err) => Promise.reject(err)
);

function subscribeTokenRefresh(cb) {
  tokenSubscribers.push(cb);
}

let isFetchingToken = false;
let tokenSubscribers = [];

function onTokenRefreshed(errRefreshing, token) {
  tokenSubscribers.map((cb) => cb(errRefreshing, token));
}
function forceLogout() {
  isFetchingToken = false;
  localStorage.clear();
  window.location = "/login";
}

API.interceptors.response.use(undefined, (err) => {
  if (err.response.config.url.includes("/login", "/refresh-token")) return Promise.reject(err);

  if (err.response.status === 403) return forceLogout();
  if (err.response.status !== 401) return Promise.reject(err);

  if (!isFetchingToken) {
    isFetchingToken = true;
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return forceLogout();

    try {
      const isRefreshTokenExpired = JWTDecode(refreshToken).exp < Date.now() / 1000;

      if (isRefreshTokenExpired) return forceLogout();
    } catch (e) {
      return forceLogout();
    }

    API.post("/refresh-token")
      .then((newAccessToken) => {
        isFetchingToken = false;

        onTokenRefreshed(null, newAccessToken);
        tokenSubscribers = [];

        localStorage.setItem("access_token", newAccessToken.data.token);
      })
      .catch(() => {
        onTokenRefreshed(new Error("Unable to refresh access token"), null);
        tokenSubscribers = [];

        forceLogout();
      });
  }

  const initTokenSubscriber = new Promise((resolve, reject) => {
    subscribeTokenRefresh((errRefreshing, newToken) => {
      if (errRefreshing) return reject(errRefreshing);
      err.config.headers.authorization = newToken;
      return resolve(API(err.config));
    });
  });
  return initTokenSubscriber;
});

export default API;
