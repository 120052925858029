import AccountProvider from "../src/context/AccountProvider";
import Navigation from "./components/Navigation";
import "rsuite/dist/rsuite.min.css";
import "../src/css/base.scss";

import Workorders from "./pages/workorders/Workorders";
import FormLogin from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";

import { Routes, Route } from "react-router-dom";
import Workorder from "./pages/workorders/Workorder";
import SingleConcreteFieldForm from "./pages/tests/concrete/SingleConcreteFieldForm";
import WorkorderForm from "./pages/workorders/Form";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Container } from "rsuite";
import WorkorderNew from "./pages/workorders/FormNew";
import WorkorderComplete from "./pages/workorders/FormComplete";
import WorkorderPickup from "./pages/workorders/FormPickup";
import WorkorderStartLabCuring from "./pages/workorders/FormLabCuring";

function App() {
  return (
    <AccountProvider>
      <Container>
        <Navigation />
        <Routes>
          <Route
            path="/"
            index={true}
            element={
              <ProtectedRoute>
                <Workorders />
              </ProtectedRoute>
            }
          />

          <Route path="workorders">
            <Route
              index={true}
              element={
                <ProtectedRoute>
                  <Workorders />
                </ProtectedRoute>
              }
            />
            <Route
              path=":workorderUUID"
              element={
                <ProtectedRoute>
                  <Workorder />
                </ProtectedRoute>
              }
            />
            <Route
              path=":workorderUUID/update"
              element={
                <ProtectedRoute>
                  <WorkorderForm />
                </ProtectedRoute>
              }
            />
            <Route
              path=":workorderUUID/complete"
              element={
                <ProtectedRoute>
                  <WorkorderComplete />
                </ProtectedRoute>
              }
            />
            <Route
              path=":workorderUUID/pickup"
              element={
                <ProtectedRoute>
                  <WorkorderPickup />
                </ProtectedRoute>
              }
            />
            <Route
              path=":workorderUUID/startlabcuring"
              element={
                <ProtectedRoute>
                  <WorkorderStartLabCuring />
                </ProtectedRoute>
              }
            />
            <Route
              path="new"
              element={
                <ProtectedRoute>
                  <WorkorderNew />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="tests">
            <Route
              index={true}
              element={
                <ProtectedRoute>
                  <SingleConcreteFieldForm />
                </ProtectedRoute>
              }
            />

            <Route path="concrete">
              <Route
                index={true}
                element={
                  <ProtectedRoute>
                    <SingleConcreteFieldForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":testID"
                element={
                  <ProtectedRoute>
                    <SingleConcreteFieldForm />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          <Route path="/login" element={<FormLogin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<span>register</span>} />
        </Routes>
      </Container>
    </AccountProvider>
  );
}

export default App;
