import { useEffect, useState, useCallback, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Form, Button, Grid, Row, Col, Input, SelectPicker, Tag, TagGroup, Loader, Panel, Container, Content, Stack, Heading, InputNumber, HStack } from "rsuite";
import SignatureCanvas from "react-signature-canvas";
import API from "../../utils/API";
import { format, parseISO, set } from "date-fns";

function WorkorderComplete(props) {
  let navigate = useNavigate();
  let { workorderUUID } = useParams();
  const [workorder, set_workorder] = useState();
  const [workorder_is_loading, set_workorder_is_loading] = useState(true);

  const sigCanvas = useRef({});

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const getWorkorder = useCallback(() => {
    API.get(`/workorders/field/${workorderUUID}`)
      .then((r) => {
        set_workorder(r.data);

        reset({
          external_id: workorderUUID,
          field_complete: true,
          work_finish: format(new Date(), "Y-MM-dd"),
          // draft_charge_cylinders: 0,
          draft_charge_additional_slumps: 0,
          draft_charge_shrinkage: 0,
          draft_charge_flex_beams: 0,
          draft_charge_air_tests: 0,
        });

        set_workorder_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [workorderUUID]);

  const onSubmit = (data) => {
    if (data.work_start_time === "") {
      data.work_start_time = null;
    }
    if (data.work_finish_time == "") {
      data.work_finish_time = null;
    }
    // await delay(5000);
    API.patch(`/workorders/field/${workorderUUID}`, data)
      .then((r) => navigate(`/workorders`))
      .catch((e) => console.log(e.response.data.errors));
  };

  useEffect(() => {
    getWorkorder();
  }, [workorderUUID, getWorkorder]);

  if (workorder_is_loading) {
    return <Loader center content="Loading..." />;
  } else {
    return (
      <Container>
        {console.log("ISSUBITTING", isSubmitting)}
        <Content>
          <Panel>
            <Stack alignItems="center" justifyContent="space-between">
              <Stack.Item>
                <Heading level={6}>Complete Workorder</Heading>
              </Stack.Item>
            </Stack>
          </Panel>

          <Panel>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="work_start_time">
                <Form.ControlLabel>Time Onsite</Form.ControlLabel>
                <Controller
                  name="work_start_time"
                  control={control}
                  rules={{ required: "Time Onsite is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input type="time" id={field.name} value={field.value || ""} onChange={(value) => field.onChange(value)} />
                      {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group controlId="work_finish_time">
                <Form.ControlLabel>Time Offsite</Form.ControlLabel>
                <Controller
                  name="work_finish_time"
                  control={control}
                  rules={{ required: "Time Offsite is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input type="time" id={field.name} value={field.value || ""} onChange={(value) => field.onChange(value)} />
                      {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>

              <HStack justifyContent="space-between">
                <Stack.Item>Total Cylinders</Stack.Item>
                <Stack.Item>
                  <Controller
                    name="draft_charge_cylinders"
                    control={control}
                    rules={{ required: "Cylinder charges required" }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputNumber id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />
                        {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                      </>
                    )}
                  />
                </Stack.Item>
              </HStack>

              <HStack justifyContent="space-between">
                <Stack.Item>Slumps Only/Extra Slumps</Stack.Item>
                <Stack.Item>
                  <Controller
                    name="draft_charge_additional_slumps"
                    control={control}
                    rules={{ required: "slump charges required" }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputNumber id={field.name} defaultValue={0} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />
                        {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                      </>
                    )}
                  />
                </Stack.Item>
              </HStack>

              <HStack justifyContent="space-between">
                <Stack.Item>Shrinkages</Stack.Item>
                <Stack.Item>
                  <Controller
                    name="draft_charge_shrinkage"
                    control={control}
                    rules={{ required: "Shrinkage charges required" }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputNumber id={field.name} defaultValue={0} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />
                        {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                      </>
                    )}
                  />
                </Stack.Item>
              </HStack>

              <HStack justifyContent="space-between">
                <Stack.Item>Flex Beams</Stack.Item>
                <Stack.Item>
                  <Controller
                    name="draft_charge_flex_beams"
                    control={control}
                    rules={{ required: "Flex Beam charges required" }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputNumber id={field.name} defaultValue={0} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />
                        {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                      </>
                    )}
                  />
                </Stack.Item>
              </HStack>

              <HStack justifyContent="space-between">
                <Stack.Item>Air Tests</Stack.Item>
                <Stack.Item>
                  <Controller
                    name="draft_charge_air_tests"
                    control={control}
                    rules={{ required: "Air charges required" }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputNumber id={field.name} defaultValue={0} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />
                        {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                      </>
                    )}
                  />
                </Stack.Item>
              </HStack>

              <div className="sign-area">
                <div className="sign-header">Site Signature</div>
                <Form.Group controlId="field_customer">
                  <Controller
                    control={control}
                    rules={{ required: "A signature is required" }}
                    name="field_signature"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <>
                        <SignatureCanvas ref={sigCanvas} onEnd={() => onChange(sigCanvas.current.toDataURL())} canvasProps={{ className: "sign-body" }} />
                        {error && <Form.HelpText style={{ color: "red" }}>{error.message}</Form.HelpText>}
                      </>
                    )}
                  />
                </Form.Group>
              </div>
              <Form.Group controlId="field_signature_name">
                <Form.ControlLabel>Signed By</Form.ControlLabel>
                <Controller
                  name="field_signature_name"
                  control={control}
                  rules={{ required: "Signed by is required" }}
                  render={({ field }) => (
                    <>
                      <Input {...field} value={field.value || ""} />
                      {errors.field_signature_name && <Form.HelpText style={{ color: "red" }}>{errors.field_signature_name.message || "This field is required"}</Form.HelpText>}
                    </>
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Button block size="lg" appearance="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
                  Complete Workorder
                </Button>
              </Form.Group>
            </Form>
          </Panel>
        </Content>
      </Container>
    );
  }
}

export default WorkorderComplete;
