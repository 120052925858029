import React, { useContext } from "react";

import { Link, NavLink } from "react-router-dom";
import { AccountConsumer } from "../context/AccountProvider";
import { Navbar, Nav } from "rsuite";

export default function Navigation() {
  let acc = useContext(AccountConsumer);
  return acc.user_id ? (
    <Navbar>
      <Nav>
        <Nav.Item as={Link} to="/workorders" eventKey="1">
          Workorders
        </Nav.Item>
      </Nav>
      <Nav pullRight>
        <Nav.Item>{acc.first_name}</Nav.Item>
      </Nav>
    </Navbar>
  ) : null;
}
