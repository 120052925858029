import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { parseISO, format, isToday, isYesterday, isTomorrow, differenceInDays } from "date-fns";

import { AiOutlinePlusCircle } from "react-icons/ai";

import {
  TagGroup,
  Tag,
  Container,
  Header,
  Content,
  FlexboxGrid,
  Panel,
  Divider,
  IconButton,
  ButtonToolbar,
  List,
  Grid,
  Row,
  Col,
  HStack,
  Stack,
  Text,
  Heading,
  Message,
  Loader,
} from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

import API from "../../utils/API";

function Workorders() {
  const [workorders, set_workorders] = useState();
  const [workorder_is_loading, set_workorder_is_loading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    API.get("/workorders/field/")
      .then((r) => {
        set_workorders(r.data);
        set_workorder_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  const getRelativeDay = (date) => {
    // const parsedDate = parseISO(date.split("Z")[0]);
    const diffDays = differenceInDays(new Date(), date);

    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else if (isTomorrow(date)) {
      return "Tomorrow";
    } else if (diffDays > 3) {
      return format(date, "eeee");
    } else {
      return format(date, "yyyy-MM-dd");
    }
  };

  if (workorder_is_loading) {
    return <Loader center content="Loading..." />;
  } else
    return (
      <Container>
        <Content>
          <Panel>
            <Header>
              <ButtonToolbar style={{ justifyContent: "flex-end", marginBottom: "1rem" }}>
                <IconButton size="lg" block appearance="primary" color="blue" onClick={() => navigate("/workorders/new")} icon={<AddOutlineIcon />}>
                  Create Workorder
                </IconButton>
              </ButtonToolbar>
            </Header>
            {workorders ? (
              workorders.map((w) => {
                const pickedUpCount = w?.concrete_samples?.reduce((acc, sample) => {
                  return acc + (sample.cylinder_specimens?.filter((specimen) => specimen.picked_up).length || 0);
                }, 0);

                const curingStartCount = w?.concrete_samples?.reduce((acc, sample) => {
                  return acc + (sample.cylinder_specimens?.filter((specimen) => specimen.start_lab_curing).length || 0);
                }, 0);

                const TotalCount = w?.concrete_samples?.reduce((acc, sample) => {
                  return acc + (sample.cylinder_specimens?.filter((specimen) => specimen).length || 0);
                }, 0);

                return (
                  <FlexboxGrid.Item key={w.id} colspan={24}>
                    <Panel
                      bodyFill
                      bordered
                      style={{
                        borderLeft: w.field_complete ? "8px solid #4caf50" : "8px solid #fa8900",
                        margin: "0.75rem 0",
                        padding: "0.5rem",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.2s",
                        borderRadius: "0",
                      }}
                      onClick={() => navigate(`/workorders/${w.external_id}`)}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                      <Grid>
                        <Row>
                          <Col xs={24}>
                            <HStack justifyContent="space-between" style={{ marginTop: "0.25rem" }}>
                              <Stack.Item>
                                <Heading level={6}>#{w.id}</Heading>
                              </Stack.Item>
                              <Stack.Item>
                                <TagGroup>
                                  {w.field_complete ? (
                                    <>
                                      {w.work_start_time && w.work_finish_time && (
                                        <>
                                          {w.work_start_time && format(parseISO(w.work_start_time.split("Z")[0]), "HH:mm")}
                                          {" - "}
                                          {w.work_finish_time && format(parseISO(w.work_finish_time.split("Z")[0]), "HH:mm")}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <Tag size="sm" style={{ borderRadius: "0.25rem", padding: "0.20rem 0.20rem" }} color={w.field_complete ? "green" : "orange"}>
                                      Incomplete
                                    </Tag>
                                  )}
                                </TagGroup>
                              </Stack.Item>
                            </HStack>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24}>
                            <HStack justifyContent="space-between" style={{ marginTop: "0.5rem" }}>
                              <Stack.Item>
                                <Text>{w.work_date && getRelativeDay(parseISO(w.work_date.split("Z")[0]))}</Text>
                              </Stack.Item>
                            </HStack>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24}>
                            {w.project && (
                              <>
                                <Text style={{ fontSize: "1rem" }}>
                                  Project: <span style={{ fontWeight: "bold" }}>{w.project.name}</span>
                                </Text>
                                <Text muted style={{ fontSize: "0.875rem" }}>
                                  Location: {w.project?.locations[0]?.address_line_1} {w.project?.locations[0]?.address_line_2}
                                </Text>
                              </>
                            )}
                            {w.field_customer && (
                              <>
                                <Text style={{ fontSize: "1rem" }}>
                                  Customer: <span style={{ fontWeight: "bold" }}>{w.field_customer}</span>
                                </Text>
                                <Text muted style={{ fontSize: "0.875rem" }}>
                                  Location: {w?.field_address_line_1} {w?.field_address_line_2}
                                </Text>
                              </>
                            )}
                          </Col>
                        </Row>

                        {w?.field_instructions && (
                          <Row>
                            <Col xs={24}>
                              <Message type="warning" className="fi_msg" style={{ marginTop: "0.5rem", padding: "0rem", borderRadius: "0.25rem" }}>
                                {w?.field_instructions}
                              </Message>
                            </Col>
                          </Row>
                        )}

                        {w?.field_complete && TotalCount && (
                          <>
                            <Divider style={{ marginBottom: "0.5rem" }} />
                            <Text style={{ fontSize: "1rem" }}>{`${pickedUpCount}/${TotalCount}`} Cylinders picked up</Text>
                            <Text hidden={pickedUpCount > 0 ? false : true} style={{ fontSize: "1rem" }}>
                              {`${curingStartCount}/${TotalCount}`} Cylinders started curing
                            </Text>
                          </>
                        )}
                      </Grid>
                    </Panel>
                  </FlexboxGrid.Item>
                );
              })
            ) : (
              <FlexboxGrid.Item colspan={24}>
                <Panel bordered style={{ textAlign: "center", padding: "20px", backgroundColor: "#f7f7f7" }}>
                  <span style={{ fontSize: "16px", color: "#333" }}>You have no workorders in the past 5 days</span>
                </Panel>
              </FlexboxGrid.Item>
            )}
          </Panel>
        </Content>
      </Container>
    );
}

export default Workorders;
