import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { AiOutlinePlusCircle, AiOutlineCheckCircle, AiOutlineEdit } from "react-icons/ai";
import { Container, Content, Modal } from "rsuite";
import { Message, Table, List, Loader, Panel, Stack, VStack, Text, Placeholder, HStack, Divider, Grid, Row, Col, Button, ButtonToolbar, ButtonGroup, IconButton } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import TrashIcon from "@rsuite/icons/Trash";

import TimeIcon from "@rsuite/icons/Time";
import API from "../../utils/API";

const { Column, HeaderCell, Cell } = Table;

function Workorder() {
  let navigate = useNavigate();
  let { workorderUUID } = useParams();
  const [workorder, set_workorder] = useState();
  const [workorder_is_loading, set_workorder_is_loading] = useState(true);

  const [open, setOpen] = useState(false);
  const [delete_sample_id, set_delete_sample_id] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getWorkorders = useCallback(() => {
    API.get(`/workorders/field/${workorderUUID}`)
      .then((r) => {
        set_workorder(r.data);
        set_workorder_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [workorderUUID]);

  useEffect(() => {
    getWorkorders();
  }, [workorderUUID, getWorkorders]);

  const has_previous = workorder && workorder?.concrete_samples?.reduce((prev, current) => (+prev.id > +current.id ? prev : current)).id;
  const hasUnpickedSpecimens = workorder?.concrete_samples?.some((sample) => sample?.cylinder_specimens?.some((specimen) => !specimen.picked_up));
  const hasUnCuredSpecimens = workorder?.concrete_samples?.some((sample) => sample?.cylinder_specimens?.some((specimen) => !specimen.start_lab_curing));

  if (workorder_is_loading) {
    return <Loader center content="Loading..." />;
  } else {
    return (
      <Container>
        <Content>
          <Panel header={`Workorder #${workorder.id}`}>
            <VStack>
              {workorder.project ? (
                <Stack.Item>
                  <Stack.Item>Work Date: {workorder.work_date && format(parseISO(workorder.work_date), "eeee dd/M")}</Stack.Item>
                  <Stack.Item>Project: {workorder.project.name}</Stack.Item>
                  <Stack.Item>Loc: {workorder.project.locations && workorder.project.locations[0].address_line_2}</Stack.Item>
                </Stack.Item>
              ) : (
                <Stack.Item>
                  <Stack.Item>Work Date: {workorder.work_date && format(parseISO(workorder.work_date), "eeee dd/M")}</Stack.Item>
                  <Stack.Item>Customer: {workorder.field_customer ? workorder.field_customer : workorder.field_address_line_1}</Stack.Item>
                  <Stack.Item>Loc: {workorder.field_address_line_2}</Stack.Item>
                </Stack.Item>
              )}
            </VStack>

            {!workorder.field_complete && (
              <ButtonToolbar style={{ marginTop: "0.5rem", justifyContent: "space-between" }}>
                {has_previous ? (
                  <IconButton size="md" icon={<PlusIcon />} onClick={() => navigate(`/tests/concrete/?woid=${workorderUUID}&wo=${workorder.id}&cp=${has_previous}`)}>
                    Add Sample
                  </IconButton>
                ) : (
                  <IconButton size="md" icon={<PlusIcon />} onClick={() => navigate(`/tests/concrete/?woid=${workorderUUID}&wo=${workorder.id}`)}>
                    Add Sample
                  </IconButton>
                )}

                <IconButton size="md" appearance="primary" color="green" icon={<CheckRoundIcon />} onClick={() => navigate(`/workorders/${workorderUUID}/complete`)}>
                  Complete Workorder
                </IconButton>
              </ButtonToolbar>
            )}

            {workorder.field_complete && hasUnpickedSpecimens && (
              <ButtonToolbar style={{ marginTop: "0.5rem", justifyContent: "space-between" }}>
                <IconButton block size="lg" appearance="ghost" color="blue" icon={<TimeIcon />} onClick={() => navigate(`/workorders/${workorderUUID}/pickup`)}>
                  Pick Up Samples
                </IconButton>
              </ButtonToolbar>
            )}

            {workorder.field_complete && !hasUnpickedSpecimens && hasUnCuredSpecimens && (
              <ButtonToolbar style={{ marginTop: "0.5rem", justifyContent: "space-between" }}>
                <IconButton block size="lg" appearance="primary" color="blue" icon={<TimeIcon />} onClick={() => navigate(`/workorders/${workorderUUID}/startlabcuring`)}>
                  Start Lab Curing
                </IconButton>
              </ButtonToolbar>
            )}

            <Divider />

            {workorder_is_loading && <Placeholder.Grid rows={5} columns={6} active />}
            {workorder?.concrete_samples?.map((cs) => (
              <div key={cs.id} style={{ padding: "0.5rem", marginBottom: "1rem", backgroundColor: "#f5f8fa" }}>
                <Grid
                  onClick={() => {
                    !workorder.field_complete ? navigate(`/tests/concrete/?woid=${workorderUUID}&wo=${workorder.id}&csid=${cs.id}`) : navigate("#");
                  }}
                >
                  <Row>
                    <Col xs={12}>Mix</Col>
                    <Col xs={12}>{cs.mix_code}</Col>
                  </Row>

                  <Row>
                    <Col xs={12}>Load / Prog:</Col>
                    <Col xs={12}>
                      {cs.load_size}/{cs.progressive_total}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>Sample Time</Col>
                    <Col xs={12}>{cs.sample_time && format(parseISO(cs.sample_time.split("Z")[0]), "HH:mm")}</Col>
                  </Row>

                  <Row>
                    <Col xs={12}>Pour Loc</Col>
                    <Col xs={12}>{cs.pour_location}</Col>
                  </Row>
                </Grid>

                <Table
                  autoHeight
                  data={cs?.cylinder_specimens?.sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id)) || []}
                  rowHeight={25}
                  headerHeight={25}
                  onClick={() => {
                    !workorder.field_complete ? navigate(`/tests/concrete/?woid=${workorderUUID}&wo=${workorder.id}&csid=${cs.id}`) : navigate("#");
                  }}
                >
                  <Column width={65} align="left" fixed>
                    <HeaderCell style={{ padding: "2px" }}>ID</HeaderCell>
                    {/* <Cell style={{ padding: "2px", fontWeight: "bold" }} dataKey="specimen_id" /> */}
                    <Cell dataKey="specimen_id" style={{ padding: "2px" }}>
                      {(rowData) => (
                        <>
                          {cs.id}/<b>{rowData.specimen_id}</b>
                        </>
                      )}
                    </Cell>
                  </Column>

                  <Column>
                    <HeaderCell style={{ padding: "2px" }}>Mould</HeaderCell>
                    <Cell style={{ padding: "2px" }} dataKey="mould_number" />
                  </Column>

                  <Column width={65}>
                    <HeaderCell style={{ padding: "2px" }}>Age</HeaderCell>
                    <Cell style={{ padding: "2px" }} dataKey="days_requested" />
                  </Column>

                  <Column>
                    <HeaderCell style={{ padding: "2px" }}>Test Date</HeaderCell>
                    <Cell style={{ padding: "2px" }}>{(rowData) => rowData.test_date && format(parseISO(new Date(rowData.test_date.split("T")[0]).toISOString()), " dd/M")}</Cell>
                  </Column>

                  <Column>
                    <HeaderCell style={{ padding: "2px" }}>Cap Type</HeaderCell>
                    <Cell style={{ padding: "2px" }} dataKey="cap_type" />
                  </Column>
                </Table>
                {!workorder.field_complete && (
                  <HStack justifyContent="flex-end">
                    <Stack.Item>
                      <Text size="sm" onClick={handleOpen} style={{ marginTop: "10px" }} muted>
                        Delete Sample
                      </Text>
                    </Stack.Item>
                  </HStack>
                )}
              </div>
            ))}

            <Modal size="lg" open={open} onClose={() => setOpen(false)}>
              <Modal.Header>
                <Modal.Title>Delete Sample</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to delete this sample?</Modal.Body>
              <Modal.Footer>
                <Button startIcon={<TrashIcon />} onClick={handleClose} appearance="primary" color="red">
                  Delete Permanently
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </Panel>
        </Content>
      </Container>
    );
  }
}

export default Workorder;
